:root {
  // Client colors fallback, values provided by BE
  --primary-color: #1c2541;
  --secondary-color: #666666;
  --brand-bar-color: #ffffff;
  --button-color: #ff8200;
  --font-color: #000;
  --logo-bkgrd-color: #fff;
  --brand-accent-color: #d7231d;
  --navbar-bg-color: #56585a;
  --navbar-bg-color-hover: #404040;
  --navbar-bg-separator-color: #ffffff;
  --control-primary-color: #165695;
  --control-primary-color-darker: #002867;
  --homepage-bg-color: #ffffff;

  // Main colors
  --white-opaque: rgba(255, 255, 255, 0.8);
  --white: #fff;
  --black: #000;

  --light-blue: #004d8a;
  --medium-blue: #165695;
  --dark-blue: #002867;
  --darker-blue: #1c2541;

  --error-color-1: #d52b1e;
  --error-color-2: #e12222;
  --warning-color: #faa500;
  --success-color: #367e12;
  --info-color: #0080bb;

  --grey-0: #00000029;
  --grey-1: #f0f1f2;
  --grey-2: #f5f7fa;
  --grey-3: #cccccc;
  --grey-4: #bbbcbc;
  --grey-5: #b2b4b4;
  --grey-6: #9a9a9a;
  --grey-7: #888c8d;
  --grey-8: #707070;
  --grey-9: #56585a;
  --grey-10: #404040;
  --border-color: rgba(112, 112, 112, 0.25);
  --border-color-2: rgba(112, 112, 112, 0.45);
  --border-color-3: rgba(112, 112, 112, 0.75);
  --border-color-4: rgba(112, 112, 112, 1);

  // Old scss variables, might be removed after restyle
  --checkBox-background: #727272;
  --color-primary: #2e468c;
  --color-primary-dark: #1c2541;
  --color-primary-light: #4a5b8c;
  --color-secondary: #40361b;
  --color-secondary-light: #8c6e20;
  --color-text: #111111;

  // Font-families
  --main-font-family: 'Poppins', 'Courier New', 'Courier', 'monospace';
  --secondary-font-family: 'Montserrat', 'DejaVu Sans', 'Verdana', 'sans‑serif';

  // Font-sizes
  --font-size-2x-sm: 0.625em; // 10px
  --font-size-x-sm: 0.75em; // 12px
  --font-size-sm: 0.8125em; // 13px
  --font-size-md: 0.875em; // 14px
  --font-size-lg: 1em; // 16px
  --font-size-xl: 1.125em; // 18px
  --font-size-2xl: 1.3125em; // 21px
  --font-size-3xl: 1.5em; // 24px
  --font-size-4xl: 1.75em; // 28px

  // Font-weights
  --light-weight: 300;
  --normal-weight: 400;
  --medium-weight: 500;
  --semibold-weight: 600;
  --bold-weight: 700;

  // Gaps
  --gap-tiny: 4px; // 4px
  --gap-small: calc(var(--gap-tiny) * 2); // 8px
  --gap-medium: calc(var(--gap-small) * 2); // 16px
  --gap-large: calc(var(--gap-small) * 3); // 24px
  --gap-xl: calc(var(--gap-small) * 4); // 32px
  --gap-2xl: calc(var(--gap-small) * 5); // 40px

  // border radius
  --radius-small: 4px;
  --radius-medium: 6px;
  --radius-large: 8px;

  --shadow-1: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  --shadow-2: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  --shadow-3: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  --shadow-reversed-1: 0px -2px 1px -1px rgb(0 0 0 / 20%), 0px -1px 1px 0px rgb(0 0 0 / 14%),
    0px -1px 3px 0px rgb(0 0 0 / 12%);
  --shadow-reversed-2: 0px -3px 1px -2px rgb(0 0 0 / 20%), 0px -2px 2px 0px rgb(0 0 0 / 14%),
    0px -1px 5px 0px rgb(0 0 0 / 12%);
  --shadow-reversed-3: 0px -3px 3px -2px rgb(0 0 0 / 20%), 0px -3px 4px 0px rgb(0 0 0 / 14%),
    0px -1px 8px 0px rgb(0 0 0 / 12%);

  // material transition
  --animation-standrad: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-out: cubic-bezier(0.4, 0, 1, 1);
}
