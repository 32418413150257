.ph-dialog-card {
  background: var(--logo-bkgrd-color);
  border-radius: var(--radius-small);
  box-shadow: var(--shadow-2);
  padding: var(--gap-large);
  border: 1px solid var(--border-color);
  gap: var(--gap-medium);
  flex-flow: column;
  display: flex;
}

.ph-dialog {
  > * {
    @extend .ph-dialog-card;
    min-width: 312px;
  }
}

[ph-dialog-actions] {
  grid-template-columns: repeat(2, 1fr);
  margin-top: var(--gap-small);
  justify-content: flex-end;
  gap: var(--gap-small);
  margin-left: auto;
  display: grid;
}

[ph-dialog-title] {
  font-family: var(--main-font-family);
  font-weight: var(--medium-weight);
  font-size: var(--font-size-lg);
  margin-bottom: 0;
}

[ph-dialog-header] {
  gap: var(--gap-medium);
  align-items: center;
  display: flex;


  :last-child {
    margin-left: auto;
  }
}
