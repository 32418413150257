// Updated CSS variables
@import './styles/variables';

// components
@import './styles/components/badge';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'app/shared/index';

html,
body {
  height: 100%;
  width: 100%;
  background: var(--white);
  margin: 0;
}

body {
  background-color: var(--homepage-bg-color);
}

// Okta widget styles for IDP flow
#okta-sign-in {
  .social-auth-button.social-auth-general-idp-button.idp-login.link-button {
    padding: 0 60px;
    border: none;
    margin: 5px 0 0;
    color: var(--white);
    background-image: none;
    background-color: var(--control-primary-color);
    font-size: var(--font-size-xl);
    font-family: var(--main-font-family);
    font-weight: var(--semibold-weight);

    &:hover,
    &:active,
    &:visited {
      background-color: var(--control-primary-color-darker);
      background-image: none;
    }
  }
}

// CSS default reset
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small,
a {
  font-family: var(--main-font-family);
  margin: 0;
}

sup {
  line-height: 1;
}

img,
svg {
  vertical-align: middle;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.cdk-overlay-backdrop {
  z-index: 999; // allow popup notifications to be shown over backdrop
}
