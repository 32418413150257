[ph-icon-button].ph-icon-button {
  font-family: var(--main-font-family);
  font-weight: var(--medium-weight);
  font-size: var(--font-size-md);
  color: var(--font-color);
  gap: var(--gap-medium);
  text-decoration: none;
  align-items: center;
  background: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  border: 0;

  ph-icon {
    transition-property: color;
  }

  span,
  p {
    position: relative;

    &::after {
      background: var(--brand-accent-color);
      transition-property: opacity;
      position: absolute;
      height: 1.25px;
      width: 100%;
      content: '';
      opacity: 0;
      bottom: 0;
      left: 0;
    }
  }

  &:hover {
    ph-icon {
      color: var(--brand-accent-color);
    }

    span,
    p {
      &::after {
        opacity: 1;
      }
    }
  }
}
