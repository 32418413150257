@import './directives/icon-button/icon-button.directive.scss';
@import '../core/services/notification/index.scss';
@import '../core/services/overlay/index.scss';
@import '../core/services/dialog/index.scss';

.ph-divider,
.ph-horizontal-divider {
  background: var(--grey-8);
  flex-shrink: 0;
  height: 1px;
  width: 1px;
}

.ph-divider {
  height: 100%;
}

.ph-horizontal-divider {
  width: 100%;
}

.ph-clickable {
  position: relative;
  cursor: pointer;

  &:hover::after {
    opacity: 0.5;
  }

  &:active::after {
    opacity: 1;
  }

  &::after {
    height: calc(100% + var(--gap-small));
    width: calc(100% + var(--gap-small));
    border-radius: var(--radius-small);
    left: calc(var(--gap-tiny) * -1);
    top: calc(var(--gap-tiny) * -1);
    background: rgba(#000, 0.2);
    transition-property: opacity;
    pointer-events: none;
    position: absolute;
    content: '';
    opacity: 0;
  }
}

[ph-overlay-close]:not([ui-ph-button]):not([ph-icon-button])::after {
  border-radius: 50%;
}

[phTooltipStandalone],
[ph-overlay-close]:not([ui-ph-button]):not([ph-icon-button]) {
  @extend .ph-clickable;
}

*,
*::after,
*::before {
  transition-timing-function: var(--animation-in);
  transition-duration: 300ms;
  transition-property: none;

  &:hover {
    transition-timing-function: var(--animation-out);
    transition-duration: 200ms;
  }
}
