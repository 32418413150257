.ph-notification-level-0 {
  --notification-color: transparent;
}

.ph-notification-level-1 {
  --notification-color: var(--info-color);
}

.ph-notification-level-2 {
  --notification-color: var(--warning-color);
}

.ph-notification-level-3 {
  --notification-color: var(--success-color);
}

.ph-notification-level-4 {
  --notification-color: var(--error-color-1);
}

.ph-notification-level-5 {
  --notification-color: var(--grey-9);
}
