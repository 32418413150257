.ph-badge-container {
  --bg-color: var(--error-color-1);
  --color: var(--white);

  position: relative !important;
  overflow: visible !important;
}

.ph-badge {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
  color: var(--color);
  border-radius: 50px;
  box-shadow: var(--shadow-1);
  font-family: var(--main-font-family);
  user-select: none;
  z-index: 1;

  &--small {
    font-size: var(--font-size-x-sm);
    line-height: var(--font-size-x-sm);
    width: 15px;
    height: 15px;
  }

  &--medium {
    font-size: var(--font-size-md);
    line-height: var(--font-size-md);
    width: 20px;
    height: 20px;
  }

  &--large {
    font-size: var(--font-size-xl);
    line-height: var(--font-size-xl);
    width: 25px;
    height: 25px;
  }

  &--top {
    top: -10px;
  }

  &--bottom {
    bottom: -10px;
  }

  &--left {
    left: -10px;
  }

  &--right {
    right: -10px;
  }
}
